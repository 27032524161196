<template>
  <div class="content">
    <el-container>
      <el-aside width="300px">
        <div class="left">
          <div class="block">
            <el-image
              style="width: 80px; height: 80px"
              class="left-header"
              :src="avater"
            >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            <span class="demonstration">{{ name }}</span>
            <span class="demonstration" style="font-size:14px;font-weight:normal;line-height:1;">服务商星级</span>
          </div>
        </div>
        <el-aside width="300px">
          <div class="left-list">
            <div class="left-lists">
              <!--纵向子级选项卡-->
              <ul
                :class="currentId == index ? 'isShow' : 'hidden'"
                v-for="(item, index) in leftList"
                :key="index"
              >
                <li
                  :class="currentID2 == index ? 'active1' : ' '"
                  v-for="(itemc, index) in leftList"
                  :key="index"
                  @click="handleChild(index)"
                >
                  <router-link :to="itemc.path"
                    ><div style="width: 100%">
                      {{ itemc.title }}
                    </div></router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </el-aside>
      </el-aside>
      <el-container>
        <el-main>
          <div class="right">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { showsidentify } from "../../API/identify.js";
export default {
  components: {},
  data() {
    return {
      avater: require('../../assets/image/ygtx.jpg'), //头像
      name: "",
      leftList: [

        { title: "员工信息", path: "/Services/EmPloyees/?id=1" },
        //  { title: "订单管理", path: "/Services/balance/?id=0" },
       // { title: "待办订单", path: "/Services/TodoOrder/?id=2" },
        // { title: "员工订单详情", path: "/Services/EmPloyeeDetails/?id=3" },
        { title: "订单管理", path: "/Services/OrderStatus/?id=4" },
        // { title: "订单统计", path: "/Services/Statistics/?id=5" },
        { title: "安全设置", path: "/Services/Security/?id=6" },
        { title: "专属二维码", path: "/Services/ecode/?id=7" },
        { title: "分享链接", path: "/Services/Share/?id=8" },
        // { 'title': '服务评价', 'path': '/Pending/servicepj/?id=6' },
      ],
      currentId: 0,
      currentID2: 0,
      lists: [
        //准备的假数据
        { id: 0 },
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
      ],
    };
  },
  methods: {
    handleChild: function (index) {
      this.currentID2 = index;
      console.log(this.currentID2);
    },
    //弹窗
    receive: function (row) {
      //显示弹框
      this.dialog = true;
    },
    showavater() {
      var code = localStorage.getItem("code");
      var id = localStorage.getItem("id");
      showsidentify(id, code).then((res) => {
        console.log(res);
        if (res.data.data.headimg.length != 0) {
          this.avater = this.URL + res.data.data.headimg;
        }
        this.name = res.data.data.loginname;
        this.$root.$emit("next1");
      });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.handleChild(this.$route.query.id);
      console.log("params", this.$route.query.id);
    }
    this.showavater();
    let _this = this; //很重要把父路由的vue实例赋给_this
    _this.$root.$on("next", function () {
      //监听next事件，这里$root很重要，不懂去官网介绍
      _this.showavater(); //调用父路由中的方法
    });
  },
};
</script>

<style  scoped>
.content {
  width: 80%;
  height: auto;
  margin: 20px auto;
}
.left {
  width: 300px;
  height: 192px;
  background-color: #ffffff;
  border-radius: 5px;
}
.left-header {
  border-radius: 50%;
  margin: 40px 110px 0 110px;
}
.left-name {
  margin-bottom: 50px;
  text-align: center;
  position: relative;
}

.demonstration {
  width: 300px;
  height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #141414;
  line-height: 40px;
  display: block;

  text-align: center;
}
.left-list {
  width: 300px;
  margin-top: 20px;
  border-radius: 5px;
  background: #ffffff;
}

.left-lists {
  width: 300px;
  cursor: pointer;
}
.left-list-wenzi {
  width: 300px;
  height: 50px;
  line-height: 50px;
  margin-left: 20px;
}
.active1 {
  border-left: 2px solid #ff4318;
  background-color: rgba(0, 0, 0, 0.1);
}
.tabcont {
  padding: 20px;
  position: absolute;
  left: 220px;
  top: 65px;
  right: 0;
  z-index: 100;
}
.tabcontent {
  display: none;
}

.left-lists ul {
  list-style: none;
  overflow-y: auto;
}
.left-lists ul li {
  height: 50px;
  line-height: 50px;
  text-align: left;
  padding-left: 20px;
  color: #333333;
}
.left-lists ul li span {
  display: block;
}
.isShow {
  display: block;
}
.hidden {
  display: none;
}
.el-main {
  padding: 0px 0px 20px 20px;
}
.right {
  width: 98%;
  height: auto;
  padding: 10px 0px 0px 10px;
  background: #ffffff;
}
</style>
